<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <!-- <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p> -->
            <form action="#" v-if="vmSubjectModuleFormData && Object.keys(vmSubjectModuleFormData).length > 0">
              <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="validationsubject_id">
                  {{cvSubjectNameLabel}}</label>
                <input
                  v-model="vmSubjectModuleFormData.subject_name"
                  type="text"
                  class="form-control"
                  required
                  disabled/>
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationsubject_module_name">
                  {{cvSubjectModuleNameLabel}}</label>
                <input
                  v-model="vmSubjectModuleFormData.subject_module_name"
                  type="text"
                  class="form-control"
                  required/>
              </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="tprepSubjectModuleEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { socialvue } from "../../../config/pluginInit.js"
import { TPrepSubjectModules } from "../../../FackApi/api/TPrepSubjectModule.js"
import ApiResponse from "../../../Utils/apiResponse"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "TPrepSubjectModuleEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSubjectModuleObj: {
      type: Object,
      default: function () {
        return {
          "interest_name": "",
          "subject_module_name": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "tprep_subject_module_edit",
      cvCardTitle: "Edit TestSeries",
      cvCardSubHeader: "Edit TestSeries ",
      cvSubmitBtn: "Edit",
      cvSubjectNameLabel: "subject/Exam Name",
      cvSubjectModuleNameLabel: "Module Name",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "TestSeries Response",
      vmSubjectModuleFormData: {}
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.tprepSubjectModuleView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSubjectModuleFormData) {
          if (!this.vmSubjectModuleFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * tprepSubjectModuleView
     */
    async tprepSubjectModuleView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSubjectModuleFormData = this.propSubjectModuleObj
        }
        else {
          let subjectModuleId = this.$route.params.subject_module_id
          let subjectModuleViewResp = await TPrepSubjectModules.tprepSubjectModuleView(this, subjectModuleId)
          console.log(subjectModuleViewResp)
          if (subjectModuleViewResp && subjectModuleViewResp.resp_status) {
            this.vmSubjectModuleFormData = subjectModuleViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at tprepSubjectModuleView() and Exception:", err.message)
      }
    },
    /**
     * tprepSubjectModuleEdit
     */
    async tprepSubjectModuleEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let subjectModuleAddResp = await TPrepSubjectModules.tprepSubjectModuleEdit(this, this.vmSubjectModuleFormData)
        await ApiResponse.responseMessageDisplay(this, subjectModuleAddResp)

        if (subjectModuleAddResp && !subjectModuleAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSubjectModuleEditModal", this.vmSubjectModuleFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at tprepSubjectModuleEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
